@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

#start {
    position: relative;
    height: 100vh;
    background-position: cover center;

    .heading-left-content {
        display: flex;
        flex-direction: column;
        gap: 3em;
    }
    
    .heading-gradient-bg {
        height: 100vh;
        width: 100%;
        position: absolute;
        background: radial-gradient(circle, rgba(56,53,97,1) 0%, rgba(22,22,24,1) 40%);
        transform: translate(0,-60%);
    }
    
    h1 {
        font-size: 30px;
    }
    
    @include media-breakpoint-up(sm) {
        h1 {
            font-size: 40px;
        }
    }
    
    @include media-breakpoint-up(lg) {
        h1 {
            font-size: 60px;
        }
    }
}

