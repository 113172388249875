@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


#kontakt {
    background-image: url("../../assets/images/contact-bg-mobile.webp");
    background-repeat: no-repeat;
    background-attachment: fixed;

    h1 {
        margin-bottom: 80px;
    }

    .contact {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: center;

        &__serparator {
            font-size: 20px;
        }
    }


    .contact-form-container {
        width: 100%;
        max-width: 550px;
        background-color: rgba(56, 53, 97, 0.7);
        padding: 15px;
        border-radius: 8px;
    }
}

@include media-breakpoint-up(md) {
    #kontakt {
        background-image: url("../../assets/images/contact-bg.webp");
    }
}