$base-color: var(--violet);
$secondary-color: var(--darker-violet);
$time: 7s;

.device-animation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s linear;
    height: 260px;

    .screen {
        position: relative;
        background: $base-color;
        width: 280;
        height: 170px;
        border-radius: 15px;
        padding: 10px 7px 10px 7px;
    
        animation-name: screen;
        animation-duration: $time;
        animation-iteration-count: infinite;
    
        &__interior {
            background: linear-gradient(45deg, rgba(0,0,0,1) 25%, rgba(161,161,161,1) 75%);
            height: 100%;
            border-radius: 2px;
        }
    
        &__dot {
            background: var(--white);
            position: absolute; 
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            bottom: 2px;
            
            animation-name: dot;
            animation-duration: $time;
            animation-iteration-count: infinite;
        }
    }
  
    .leg {
        background: $secondary-color;
        height: 40px;
        width: 40px;
        
        animation-name: leg;
        animation-duration: $time;
        animation-iteration-count: infinite;
    }

    .stand {
        background: $base-color;
        height: 10px;
        width: 80px;
        border-radius: 2px;
        
        animation-name: stand;
        animation-duration: $time;
        animation-iteration-count: infinite;
    }
}
  

@keyframes screen {
    0%  {width: 280px; height: 170px; padding-bottom: 10px}
    12.5%  {width: 280px; height: 170px; padding-bottom: 10px}

    25% {width: 250px; height: 140px; padding-bottom: 10px;}
    37.25% {width: 250px; height: 140px; padding-bottom: 10px}

    50%  {width: 130px; height: 170px; padding-bottom: 15px ;}
    62.5%  {width: 130px; height: 170px; padding-bottom: 15px ;}

    75% {width: 60px; height: 100px; padding-bottom: 15px }
    87.5% {width: 60px; height: 100px; padding-bottom: 15px ;}

    100% {width: 280px; height: 170px; padding-bottom: 10px ;}
}
  
@keyframes leg {
    0%  { height: 40px}
    12.5%  { height: 40px}
    
    25% { height: 0px;}
    37.25% { height: 0px;}
    
    50%  { height: 0px;}
    62.5%  { height: 0px}
    
    75% { height: 0px;}
    87.5% { height: 0px;}
    
    100% { height: 40px}
}

  
@keyframes stand {  
    0%  { width: 80px;}
    12.5%  { width: 80px;}

    25% { width: 260px; height: 10px}
    37.25% { width: 260px; height: 10px}

    50%  { width: 0px; height: 0;}
    62.5%  { width: 0px; height: 0;}

    75% { width: 0px; height: 0;}
    87.5% { width: 0px; height: 0;}

    100% { width: 80px; height: 10px;}
}
  
@keyframes dot {  
    0%  { opacity: 0;}
    12.5%  { opacity: 0;}

    25% { opacity: 0;}
    37.25% { opacity: 0;}

    50%  { opacity: 1;}
    62.5%  { opacity: 1;}

    75% { opacity: 1;}
    87.5% { opacity: 1;}

    100% { opacity: 0;}
}
  
  
  
  
  
  
  
  
  