.box {
    border-radius: 8px;
    padding: 1.5em;
    &--violet {
        background: linear-gradient(var(--violet), var(--darker-violet));
    }

    &--y-violet-borders {
        border-left: 3px solid var(--violet);
        border-right: 3px solid var(--violet);
        background-color: rgba(22, 22, 24, 0.9);
        transition: background-color 0.2s linear;
        &:hover {
            background-color: rgba(22, 22, 24, 1);
        }
    }

    &__white {
        background-color: var(--white);
    }
}