#footer {
    color: white;

    .col-md {
        padding: 1rem;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        svg {
            margin-right: 8px;
        }
    }
}
