@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$transitionTime: 0.3s;

.navbar {
  background-color: rgba(56, 53, 97, 0);
  transition: all $transitionTime linear;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px; 
    background: linear-gradient(90deg, rgba(22,22,24,0) 5%, rgba(56, 53, 97,1) 40%, rgba(56, 53, 97,1) 60%, rgba(22,22,24,0) 95%);
  }
}
  
.navbar.scrolled {
  background-color: rgba(56, 53, 97, 1);
}
  
.nav-logo {
  height: 40px !important;
}
  
.nav-link {
  color: var(--white) !important;
  border-bottom: 1px solid rgb(124, 98, 220, 0);
  border-top: 1px solid rgb(124, 98, 220, 0);
  transition: all $transitionTime linear;
}
  
.nav-link.active, .nav-link:hover {
  color: var(  --violet) !important;
  border-bottom: 1px solid rgb(124, 98, 220, 1);
  border-top: 1px solid rgb(124, 98, 220, 1);
}
  
.navbar-toggler {
  width: 40px;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgb(124, 98, 220)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
  
.navbar-toggler-icon {
  background-image: none !important;
}

.navbar-collapse {
  transition: all $transitionTime linear;
}

#nav:not(.scrolled) {
  .navbar-collapse.show, .navbar-collapse.collapsing {
    background-color: var(--dark-bg);
    margin-bottom: -10px;
  }
}

// @include media-breakpoint-up(lg) {
//   #basic-navbar-nav {
//     background-color: none;
//   }
// }