.cta-button {
    color: var(--white);
    display: inline-block;
    border-radius: 8px;
    background: linear-gradient(90deg, var(--violet), var(--darker-violet));
    padding: 10px 15px;
    transition: all 200ms linear;
    cursor: pointer;
    border: none;
}

.cta-button:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 2px 2px 22px 0px var(--violet);
    -moz-box-shadow: 2px 2px 22px 0px var(--violet);
    box-shadow: 2px 2px 22px 0px var(--violet);
}

.transparent-button {
    color: #fff;
    border: 2px solid var(--violet);
    cursor: pointer;
    border-radius: 8px;
    padding: 10px 15px;
    background-color: rgba(124, 98, 220,0);
    transition: all 200ms;
    &:hover {
        background-color: rgba(124, 98, 220,.5);
    }
}