@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

:root {
  --dark-bg: rgb(22, 22, 24);
  --dark-violet: rgb(56, 53, 97);
  --violet: rgb(124, 98, 220);
  --darker-violet: rgb(69, 54, 147);
  --white: #fff;

  --bs-emphasis-color-rgb: red;
}

body {
  background-color: var(--dark-bg);
}

section {
  background-color: var(--dark-bg);
  color: white !important;
  padding-top: 80px;
  padding-bottom: 80px;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 1.5em;
}

@include media-breakpoint-up(sm) {
  h1 {
    font-size: 2em;
  }
}

@include media-breakpoint-up(lg) {
  h1 {
    font-size: 2.5em;
  }
}

h2 {
  font-size: 1.35em;
}

@include media-breakpoint-up(sm) {
  h2 {
    font-size: 1.85em;
  }
}

@include media-breakpoint-up(lg) {
  h2 {
    font-size: 2.15em;
  }
}

h3 {
  font-size: 1.2em;
}

@include media-breakpoint-up(sm) {
  h3 {
    font-size: 1.7em;
  }
}

@include media-breakpoint-up(lg) {
  h3 {
    font-size: 2em;
  }
}


h1 {
  font-size: 1.5em;
}

@include media-breakpoint-up(sm) {
  h1 {
    font-size: 2em;
  }
}

@include media-breakpoint-up(lg) {
  h1 {
    font-size: 2.5em;
  }
}



body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(124, 98, 220,0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--dark-violet);
  outline: 1px solid var(--darker-violet);
}

.grid-center {
  display: grid;
  place-items: center;
}
