.contact-form {
    width: 100%;
    &__container {
        width: 100%;
    }
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    input, textarea {
        width: 100%;
        border: 3px solid var(--darker-violet);
        border-radius: 8px;
    }
    &__error {
        font-size: 12px;
        color: red;
        &__container {
            height: 14px;
        }
    }
}