#technologie {
    background: var(--violet);
    height: fit-content;

    .stack {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-around;
        margin-top: 80px;
        &__img {
            max-height: 120px;
        }

        &__name {
            border-radius: 8px;
            background-color: var(--dark-violet);
            margin-top: 20px;
            display: block;
            cursor: pointer;
            transition: all 0.2s linear;
            &:hover{
                transform: scale(1.1);
                -webkit-box-shadow: 3px 4px 18px 0px rgba(66, 68, 90, 1);
                -moz-box-shadow: 3px 4px 18px 0px rgba(66, 68, 90, 1);
                box-shadow: 3px 4px 18px 0px rgba(66, 68, 90, 1);
            }
        }
    }
}