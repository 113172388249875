@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

#twoje-potrzeby {
    background-image: url("../../assets/images/design-mobile.webp");
    background-position: cover center;
}

@include media-breakpoint-up(md) {
    #twoje-potrzeby {
        background-image: url("../../assets/images/design.webp");
    }
}