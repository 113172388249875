.console-underscore {
  display:inline-block;
  position:relative;
  top:-0.14em;
  left:10px;
}

.console-underscore--hidden {
  opacity: 0;
}
