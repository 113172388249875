$circle-size: 80px;

#jak-pracuje {
    .steps {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-around;
    }

    .step {
        position: relative;
        padding: 15px;
        padding-top: 60px;
        background: linear-gradient(90deg, var(--violet), var(--darker-violet));
        border-radius: 8px;
        margin-top: 80px;
        width: 300px;
        min-width: 240px;

        &__title {
            background-color: var(--dark-bg);
            border-radius: 8px;
            text-align: center;
            padding: 5px;
        }

        &__circle {
            position: absolute;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto;
            background-color: var(--dark-bg);
            width: $circle-size;
            height: $circle-size;
            border-radius: 50%;
            border: 2px solid var(--violet);
            transform: translate(0, -100px);
            font-size: 2em;
            font-weight: bold;
        }
    }
}
  