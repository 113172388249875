@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


#responsywnosc {
    .devices-heading {
        margin-bottom: 60px;
        &--left {
            border-right: 1px solid var(--violet);
        }
        &--right {
            border-left: 1px solid var(--violet);
        }
    }
}