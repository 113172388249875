#o-mnie {
    .about {
        &__next-level {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3em;
        }

        &__arrow {
            max-width: 300px;
            width: 60%;
            transition: all linear 0.3s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
